@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* ----------- Default Styles Start ----------- */
html {
  @apply bg-base-light dark:bg-neutral-10
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scrollbar start */
::-webkit-scrollbar {
  @apply bg-neutral-1;
  height: 3px;
  width: 8px;
}
::-webkit-scrollbar:hover {
  height: 4px;
}
.dark ::-webkit-scrollbar {
  @apply bg-neutral-9
}
/* Track */
::-webkit-scrollbar-track {
  @apply bg-neutral-1;
  border-radius: 10px;
  height: 3px;
  width: 8px;
}
.dark ::-webkit-scrollbar-track {
  @apply bg-neutral-9;
}
/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-neutral-5;
  border-radius: 10px;
}
.dark ::-webkit-scrollbar-thumb {
  @apply bg-neutral-6;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-7;
}
.dark ::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-4;
}
/* Scrollbar End */

/* ----- Text Start ----- */
.display-large {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 57px;
  line-height: 64px;
  letter-spacing: -0.25px;
}
.display-medium {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 52px;
}
.display-small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
}
.headline-large {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
}
.headline-medium {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
}
.headline-small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}
.title-large {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}
.title-medium {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.title-small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.body-large {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.body-medium {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.body-small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.label-large {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.label-medium {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.label-small {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
/* ----- Text End ----- */
/* ----------- Default Styles End----------- */

/* ----------- Animation Styles Start----------- */
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popupAnimation {
  0% {
    scale: 0%;
  }
  40% {
    scale: 110%;
  }
  100% {
    scale: 100%
  }
}
.fade-in {
  animation: fadeInAnimation;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
}
.popup {
  animation: popupAnimation;
  animation-duration: 500ms;
}
/* ----------- Animation Styles End----------- */
/* Home Styles Start */
.homepage {
  @apply bg-blue-10* w-full h-screen flex flex-col items-center;
    background-image: url("../icons/home/homepage_background_four.jpg");
    background-attachment: fixed;
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    background-size: cover;
}
.top-gradient {
  opacity: 0.95;
  background: linear-gradient(180deg, #553A2F 0%, rgba(0, 0, 0, 0.00) 100%);
  width: 100%;
  height: 30vh;
  flex-shrink: 0;
  position: fixed;
  top: 0;
}
.bottom-gradient {
  opacity: 0.8;
  background: linear-gradient(0deg, #A47044 0%, rgba(0, 0, 0, 0.00) 100%);
  width: 100%;
  height: 50vh;
  flex-shrink: 0;
  position: fixed;
  bottom: 0
}
.menu-button {
  @apply text-white font-bold text-4xl flex flex-col gap-10 p-12 fixed bottom-0;
  font-family: "Roboto";
}
.notification-pane {
  @apply w-[350px] fixed top-0 right-0 p-4 rounded-md m-8 text-neutral-9 dark:text-neutral-3 body-small flex flex-col gap-6 bg-white dark:bg-neutral-10 ;
}
.home-link-container-main {
  @apply flex flex-wrap gap-4
}
.home-link {
  @apply rounded-lg bg-[#00274C] dark:bg-yellow-5* hover:bg-[#002F5C] dark:hover:bg-yellow-6 p-3 w-56 text-center;
}
@keyframes buttonPop {
  0% {
    scale: 100%;
  }
  25% {
    scale: 110%;
  }
  50% {
    scale: 100%;
  }
  75% {
    scale: 95%;
  }
  100% {
    scale: 100%
  }
}
.home-link {
  animation: buttonPop .5s ease-in;
  /* animation-delay: 0.4s; */
}
.home-link:nth-child(2) {
  animation-delay: .1s;
}
.home-link:nth-child(3) {
  animation-delay: .2s;
}
.c5 {
  padding: 0px;
  @apply bg-white dark:bg-black
}
/* Home Styles End */
/* Announcements Styles Start */
.announcement-bg {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Announcements Styles End */

/* ----------- Left Nav Start----------- */
.link-container {
  @apply pl-2 h-8 rounded-full flex content-center items-center gap-x-4;
}
.primary-nav-icon {
  @apply w-5 h-5
}
.dashboard-logo {
  @apply h-2 w-10
}
/* ----------- Left Nav End----------- */

/* ----------- Asset Management Start----------- */
/* Default Management Component Start */
.am-landing-container {
  @apply flex flex-wrap gap-6 title-medium text-neutral-9;
}
.am-landing-container-button {
  @apply bg-white dark:bg-neutral-9 dark:text-neutral-1 shadow-light dark:shadow-dark hover:bg-blue-1 dark:hover:bg-neutral-8 focus:bg-blue-2 hover:shadow-blue dark:hover:shadow-yellow items-center rounded-lg w-[200px] py-3 px-4 flex flex-col gap-2 text-left;
  transition: ease-out 400ms;
}
/* Default Management Component End */
/* Asset Management Action Component Start */
.am-action-main {
  @apply w-full flex flex-col h-screen;
}
.am-action-container {
  @apply w-full md:h-full flex md:flex-row flex-col gap-4 md:gap-0 p-4 md:p-0
}
/* precheck Component Start */
.am-action-precheck {
  @apply bg-white dark:bg-neutral-10 border border-neutral-2 dark:border-neutral-8 md:border-l md:border-l-neutral-3 md:dark:border-l-neutral-8 md:overflow-auto text-neutral-7 dark:text-neutral-4 lg:w-96 md:w-80 w-full lg:p-4 pt-9 rounded-xl md:rounded-none pb-9;
}
.am-action-precheck-header {
  @apply title-large mb-6 text-neutral-9 dark:text-neutral-3 px-6
}
.am-action-precheck-main {
  @apply flex flex-col gap-7 px-6
}
.am-action-precheck-card {
  @apply title-medium flex flex-col gap-2
}
.am-action-precheck-card-info {
  @apply body-medium text-neutral-7 dark:text-neutral-4

}
/* precheck Component End */
.am-action-form-container {
  @apply w-full flex md:pt-16 justify-center md:overflow-auto md:p-4
}
.am-action-form {
  @apply h-fit md:max-w-sm w-fit p-6 flex flex-col gap-y-0 flex-grow bg-white dark:bg-neutral-9 rounded-xl border border-neutral-2 dark:border-neutral-8;
  animation: popupAnimation;
  animation-duration: 500ms;
}
.am-action-form-header-description {
  @apply flex flex-col gap-2 pb-6
}
.am-action-form-header {
  @apply flex gap-2 items-center title-large text-neutral-9 dark:text-neutral-1
}
.am-action-form-description {
  @apply text-neutral-7 body-medium dark:text-neutral-5
}
.am-action-form-header img {
  @apply h-5 w-fit
}
.am-action-component-main {
  @apply flex flex-col gap-y-2;
}
.am-action-component-item {
  @apply body-medium flex justify-between flex-col;
}
.am-action-component-item-header {
  @apply w-fit mb-2 body-medium flex items-center gap-1 text-neutral-9 dark:text-neutral-1;
}
.am-action-component-item-header img {
  @apply w-fit h-[14px];
}
.am-action-form-default {
  @apply bg-white dark:bg-neutral-9 rounded-md ring-1 ring-neutral-5 hover:ring-neutral-6 dark:ring-neutral-4 dark:hover:ring-neutral-3 focus:ring-blue-10* dark:focus:ring-yellow-5* focus:ring-2 focus:outline-none
}
.am-action-form-error {
  @apply bg-white rounded-md ring-1 ring-red-6* focus:ring-red-6* focus:ring-2 focus:outline-none
}
.am-action-form-error-message {
  @apply min-h-[20px] mt-[4px] body-medium flex items-start gap-1 text-red-11 dark:text-red-6*
}
.am-action-form-error-message img {
  @apply mt-[2px]
}
.am-uniqname-item {
  @apply w-32 px-2 p-[6px] dark:text-neutral-1 bg-white dark:bg-neutral-9;
}
.am-laptop-item {
  @apply w-fit flex
}
.am-laptop-item select {
  @apply w-fit p-[6px] rounded-none rounded-l-md cursor-pointer mr-[0px] focus:z-20 hover:z-10 focus:bg-white dark:focus:bg-neutral-9 text-neutral-9 dark:text-neutral-1 bg-neutral-1 dark:bg-neutral-10
}
.am-laptop-item input {
  @apply p-[6px] px-2 h-fit w-20 rounded-none rounded-r-md focus:z-20 hover:z-10 dark:text-neutral-1 bg-white dark:bg-neutral-9
}
.am-comments {
  @apply mb-6 flex flex-col justify-between gap-y-2
}
.am-comments-header {
  @apply w-fit body-medium flex items-center gap-1 dark:text-neutral-1;
}
.am-comments textarea {
  @apply bg-white dark:bg-neutral-9 w-full body-medium p-[6px] h-[72px] resize-none rounded-md text-neutral-9 dark:text-neutral-1
}
.am-submit-cancel-container {
  @apply w-full flex gap-3 justify-end
}
.am-submit-close-button {
  @apply w-[80px] h-[32px] label-large text-center border-none rounded-full flex justify-center items-center
}
.am-action-submit-button-spinner {
  @apply w-full flex justify-center
}
/* Start submit button loading dots */
.dot-container {
  display: inline-block;
  margin-bottom: 8px
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  @apply bg-white dark:bg-neutral-10;
  animation: jumpingAnimation 1.4s ease-in infinite;
  animation-delay: 0.1s;
}

.dot:nth-child(2) {
  animation-delay: 0.4s;
  margin-left: 5px;
  margin-right: 5px;
}

.dot:nth-child(3) {
  animation-delay: 0.7s;
}

@keyframes jumpingAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* End submit button loading dots */


.am-action-submit-button-spinner img, .am-action-submit-button-spinner svg {
  @apply w-5 h-5 animate-spin ml-2
}
/* Default Action Component End */
/* Action Success Start */
.am-action-success-container {
  @apply w-full bg-white dark:bg-neutral-9 items-center h-screen text-neutral-9 dark:text-neutral-1 flex flex-col gap-12
}
.am-action-success-next-steps {
  @apply pt-9 p-3 md:p-9 w-full flex flex-col gap-12  h-fit md:h-full overflow-auto bg-base-light dark:bg-neutral-10
}
.am-action-success-header {
  @apply headline-large text-blue-10* dark:text-yellow-5* flex items-center gap-2
}
.am-action-success-next-steps-card-container {
  @apply flex flex-col gap-8
}
.am-action-success-next-steps-card {
  @apply w-full rounded-md border border-neutral-2 dark:border-neutral-8 p-6 flex flex-col gap-6 body-medium bg-white
}
.am-action-success-card-bullet-container {
  @apply flex flex-col gap-2 text-neutral-8 dark:text-neutral-4 list-disc pl-4
}
.am-action-success-summary {
  @apply bg-white dark:bg-neutral-9 text-neutral-9 dark:text-neutral-1 pt-9 p-6 md:p-9 border-l border-l-neutral-3 dark:border-l-neutral-8 overflow-auto md:w-[400px] w-full
}
.am-action-success-summary-info-container {
  @apply flex flex-col gap-7 md:pb-20
}
.am-action-success-summary-info {
  @apply flex flex-col gap-1 p-2
}
.am-action-success-summary-link {
  @apply flex flex-col gap-1 p-2 w-fit hover:bg-blue-1 dark:hover:bg-neutral-9 rounded-md
}
.am-action-success-summary-info-name {
  @apply text-[14px] font-normal text-neutral-8 dark:text-neutral-4;
  font-family: "Roboto";
}
.am-action-success-summary-info-data {
  @apply flex gap-2 items-center title-medium
}
.am-action-success-summary-info-data-comment {
  @apply flex gap-2 items-start title-medium break-words
}
.am-action-success-close-button-container {
  @apply fixed md:absolute right-0 bottom-0 p-6
}
.am-action-success-close-button {
  @apply block text-center w-[80px] rounded-full bg-blue-10* dark:bg-yellow-5* label-large p-[10px] text-white dark:text-neutral-10 hover:bg-blue-9 dark:hover:bg-yellow-6
}
@keyframes infoCardAnimation {
  0% {
    scale: 100%;
  }
  40% {
    scale: 105%;
  }
  100% {
    scale: 100%
  }
}
.am-action-success-next-steps-card {
  animation: infoCardAnimation .4s ease-in;
  animation-delay: 0.4s;
}
.am-action-success-next-steps-card:nth-child(2) {
  animation-delay: 0.6s;
}

.am-action-success-next-steps-card:nth-child(3) {
  animation-delay: 0.8s;
}
/* Action Success End */
/* High Error Alert Start */
.am-error-alert-container {
  @apply fixed right-0 bottom-0 m-3 flex flex-col
}
.am-error-alert-main {
  @apply mr-8 flex flex-col gap-2 items-end
}
.am-error-alert-message {
  @apply body-small bg-neutral-1 dark:bg-neutral-9 dark:text-neutral-1 p-3 border border-neutral-3 dark:border-neutral-7 rounded-md w-[240px]
}
.am-error-alert-button-container {
  @apply flex flex-col justify-between mt-2 gap-2 w-full
}
.am-error-alert-close-button {
  @apply body-small flex-1 text-neutral-9 p-2 bg-neutral-2 dark:bg-neutral-8 hover:bg-neutral-3 dark:hover:bg-neutral-7 dark:text-neutral-1 rounded-full
}
.am-error-alert-tdx-button {
  @apply body-small text-white dark:text-neutral-10 bg-blue-10* dark:bg-yellow-5* hover:bg-blue-9 dark:hover:bg-yellow-6 p-2 rounded-full flex-1 text-center
}
.am-error-alert-squirrel-container {
  @apply flex w-full justify-end
}
.am-error-alert-squirrel-container img {
  @apply h-9 w-fit
}
/* High Error Alert End */
/* Uncaught Error Alert Start */
.am-action-uncaught-error-alert {
  @apply body-medium bg-red-1 dark:bg-red-11 text-neutral-8 p-3 rounded-md flex items-start gap-3 mt-6
}
.am-action-uncaught-error-alert img {
  @apply h-5 w-5
}
.am-action-uncaught-error-alert a {
  @apply underline
}
/* Uncaught Error Alert End */
/* ----------- Asset Management End----------- */


/* ----------- Resources Start ----------- */
.r-page {
  @apply w-full flex flex-col p-6 items-center
}
/* Resource Landing Start */
.r-landing-container {
  @apply max-w-3xl w-full mt-4 text-neutral-9 dark:text-neutral-1
}
.r-landing-title {
  @apply mb-6 headline-large text-neutral-9 dark:text-neutral-1 text-center
}
.r-landing-main {
  @apply mt-14
}
/* Resource Landing End */

/* Resource Landing Tabgroups Start */
.r-landing-tabgroups {
  @apply p-3 lg:w-60 sm:w-[48%] w-full flex items-center gap-3 shadow-light dark:shadow-dark bg-white dark:bg-neutral-9 hover:bg-blue-1 dark:hover:bg-neutral-8 focus:bg-blue-2 hover:shadow-blue dark:hover:shadow-yellow rounded-lg title-small text-neutral-9 dark:text-neutral-1;
  transition: ease-out 400ms;
}
/* Resource Landing Tabgroups End */

/* Resource Landing Tabs Start */
.r-landing-tabs {
  @apply py-2 px-4 title-medium focus:outline-none min-w-fit
}
/* Resource Landing Tabs End */

/* Resource Landing Search Start */
.r-landing-search {
  @apply relative mt-14 mb-6 w-full border border-blue-10* dark:border-yellow-5* rounded-lg
}
.r-landing-search input {
  @apply w-full pl-10 rounded-lg p-3 bg-white dark:bg-neutral-9 body-medium pr-12 focus:outline-blue-10* dark:focus:ring-yellow-5* dark:focus:ring dark:focus-visible:outline-none text-neutral-9 dark:text-neutral-1
}
.r-landing-search-clear {
  @apply absolute top-0 right-0 flex items-center justify-center h-full p-2
}
/* Resource Landing Search End */

/* Resource Landing Categories Start */
.r-landing-categories-title {
  @apply mb-6 headline-small text-left mt-20 text-neutral-9 dark:text-neutral-1
}
.r-landing-categories-container {
  @apply w-full flex flex-col gap-4
}
.r-landing-categories-cards {
  @apply flex flex-col lg:flex-row gap-4
}
.r-landing-categories-card {
  @apply flex-1 flex shadow-light dark:shadow-dark hover:shadow-blue dark:hover:shadow-yellow bg-white dark:bg-neutral-9 p-3 rounded-md gap-4 hover:bg-blue-1 dark:hover:bg-neutral-8 focus:bg-blue-2 text-neutral-9 dark:text-neutral-1;
  transition: ease-out 200ms;
}
.r-landing-categories-card img {
  @apply rounded-md h-6 w-10
}
.r-landing-categories-card-content {
  @apply body-medium
}
.r-landing-categories-training-card {
  @apply flex items-center lg:justify-center shadow-light dark:shadow-dark hover:shadow-blue dark:hover:shadow-yellow bg-white dark:bg-neutral-9 p-3 rounded-md gap-2 hover:bg-blue-1 dark:hover:bg-neutral-8 focus:bg-blue-2 text-neutral-9 dark:text-neutral-1 title-large;
  transition: ease-out 400ms;
}
/* Resource Landing Categories End */

/* Resource GroupBuilder Start */
.r-groupbuilder-nav {
  @apply text-neutral-7 dark:text-neutral-4 max-w-3xl flex min-w-full overflow-auto gap-1 body-large
}
.r-groupbuilder-container {
  @apply max-w-3xl w-full
}
.r-groupbuilder-searchbox {
  @apply relative mt-8 mb-8 w-full border border-blue-10* dark:border-yellow-5* rounded-lg
}
.r-groupbuilder-searchbox input {
  @apply w-full pl-10 rounded-lg p-3 bg-white dark:bg-neutral-9 body-medium pr-12 focus:outline-blue-10* dark:focus:ring-yellow-5* dark:focus:ring dark:focus-visible:outline-none text-neutral-9 dark:text-neutral-1
}
.r-groupbuilder-searchbox-clear {
  @apply absolute top-0 right-0 h-full p-2
}
/* Resource GroupBuilder End */

/* Resource CategoryBuilder Start */
.r-categorybuilder-nav {
  @apply text-neutral-7 dark:text-neutral-4 w-full max-w-3xl flex gap-1 body-large
}
.r-categorybuilder-container {
  @apply max-w-3xl w-full text-neutral-9 dark:text-neutral-1
}
/* Resource CategoryBuilder End */

/* Resource Links Start */
.r-links-container {
  @apply flex flex-col gap-4
}
.r-individual-link {
  @apply shadow-light dark:shadow-dark hover:shadow-blue dark:hover:shadow-yellow bg-white dark:bg-neutral-9 rounded-md p-4 hover:bg-blue-1 dark:hover:bg-neutral-8;
  transition: ease-out 400ms;
}
.r-individual-link-name {
  @apply title-medium text-blue-10* dark:text-yellow-5* mb-2
}
.r-individual-link-description {
  @apply body-medium text-neutral-9 dark:text-neutral-1
}
/* Resource Links End */

/* ----------- Resources End ----------- */